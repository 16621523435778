.layout-wrap .clear-both {
  width: 100%;
}

.proposal-list-parent,
.proposal-list-parent .child {
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}

.proposal-list-parent .child {
  @apply mr-8 opacity-0;
}

.proposal-list-parent:hover .child {
  @apply mr-0 opacity-100;
}

.wallet-details-assets-wrap .clear-both {
  @apply h-full w-full;
}

.wallet-details-assets {
  @apply absolute z-10 rounded-lg;
  left: -2px;
  top: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
