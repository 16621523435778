@import "@sundae/gov-toolkit/dist/styles.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
    .atrium-btn {
        box-shadow: inset 0px -1px 10px #ffffff0d, 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
        background: radial-gradient(115.83% 134.17% at 50% 118.06%, #ffffff33, rgba(0, 0, 0, 0.5)) !important;
        border: #ffffff66 thin solid !important;
        border-radius: 10px;
        backdrop-filter: blur(10px);
        transition: 0.6s;
        overflow: visible;
        scale: 1;
        outline: none;
        cursor: pointer;
        position: relative;
        z-index: 0;
        font-family: 'Nunito Sans';
        letter-spacing: 2px;
        font-size: 12px;
        text-transform: uppercase;
    }

    .atrium-btn-secondary {
        border-radius: 10px;
        backdrop-filter: blur(10px);
        transition: 0.6s;
        overflow: visible;
        scale: 1;
        outline: none;
        cursor: pointer;
        position: relative;
        z-index: 0;
        font-family: 'Nunito Sans';
        letter-spacing: 2px;
        font-size: 12px;
        text-transform: uppercase;

        box-shadow: 0px -1px 10px #895ea20d, 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
        background: radial-gradient(115.83% 134.17% at 50% 118.06%, #895ea233, rgba(0, 0, 0, 0.5)) !important;
        border: #895ea266 thin solid !important;
    }
}